import React from "react";
import styled from "styled-components";

interface XStrokeProps {
  color?: string;
  onClick?: () => void;
}

const StyledXStroke = styled.svg`
  & .path {
    clip-rule: evenodd;
    fill: ${(props) => props.color};
    fill-rule: evenodd;
  }
`;

const XStroke: React.FC<XStrokeProps> = ({ color = "#C94A4C", onClick }): JSX.Element => {
  return (
    <StyledXStroke
      color={color}
      className={`x-stroke`}
      onClick={onClick}
      fill="none"
      height="16"
      viewBox="0 0 24 24"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
        fillRule="evenodd"
      />
    </StyledXStroke>
  );
};

export default XStroke;
