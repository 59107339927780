export enum SidebarTutorialSlugs {
  dashboard = 'dashboard',
  logo = 'logo',
  myEvents = 'myEvents',
  myPrograms = 'myPrograms',
  myReporting = 'myReporting',
  myProfile = 'myProfile',
  materialsLibrary = 'materialsLibrary',
  publicEvents = 'publicEvents',
  openTutorialIcon = 'openTutorialIcon',
}
