"use client";

import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { TourStepProps } from "antd/lib/tour/interface";
import {
  dashboardUrl,
  favoritesProgramsUrl,
  futureMyEventsUrl,
  materialsLibraryUrl,
  MY_EVENTS_BASE_PATH,
  MY_PROFILE_BASE_PATH,
  MY_PROGRAMS_BASE_PATH,
  MY_REPORTING_BASE_PATH,
  myHealingReportingUrl,
  ongoingMyEventsUrl,
  pastMyEventsUrl,
  personalInfoUrl,
  PUBLIC_EVENTS_BASE_PATH,
  publicEventsUrl,
} from "@/utils/appUrls";
import { useTranslation } from "react-i18next";
import { useRouter, useSearchParams } from "next/navigation";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { LocalStorageKeys } from "@/constants/common";
import { useAuth } from "@/context/AuthContext";
import { SidebarTutorialSlugs } from "@/constants/tour";
import { useUserData } from "@/hooks/useUserData";
import { usePath } from "@/hooks/usePath";
import { Grid } from "antd/lib";
import { useMenu } from "@/context/MenuContext";

export interface SidebarTutorialProviderProps {
  cookiesModalIsOpen: boolean;
  bannersAreLoaded: boolean;
  children: ReactNode;
}

type TourWasShown = { main: boolean; eventDetailsPage: boolean; openTutorialIcon: boolean };

type SidebarTutorialType = {
  sidebarTutorialSlug: SidebarTutorialSlugs | undefined;
  tourWasShown: TourWasShown;
  sidebarTourIsOpen: boolean;
  pageTourIsOpen: boolean;
  eventDetailsPageTourIsOpen: boolean;
  steps: TourStepProps[];
  dashboardRef?: React.MutableRefObject<any>;
  logoRef?: React.MutableRefObject<any>;
  materialsLibraryRef?: React.MutableRefObject<any>;
  myEventsRef?: React.MutableRefObject<any>;
  myProgramsRef?: React.MutableRefObject<any>;
  myReportingRef?: React.MutableRefObject<any>;
  myProfileRef?: React.MutableRefObject<any>;
  publicEventsRef?: React.MutableRefObject<any>;
  openTutorialIconRef?: React.MutableRefObject<any>;
  setSidebarTutorialSlug: Dispatch<SetStateAction<SidebarTutorialSlugs | undefined>>;
  setTourWasShown: Dispatch<SetStateAction<TourWasShown>>;
  onFinish: () => void;
};

const SidebarTutorialContext = createContext<SidebarTutorialType>({
  sidebarTutorialSlug: undefined,
  tourWasShown: { main: false, eventDetailsPage: false, openTutorialIcon: false },
  sidebarTourIsOpen: false,
  pageTourIsOpen: false,
  eventDetailsPageTourIsOpen: false,
  steps: [],
  setSidebarTutorialSlug: () => undefined,
  setTourWasShown: () => false,
  onFinish: () => () => {},
});

const { useBreakpoint } = Grid;

export const SidebarTutorialProvider: React.FC<SidebarTutorialProviderProps> = ({
  children,
  cookiesModalIsOpen,
  bannersAreLoaded,
}) => {
  const router = useRouter();
  const pathname = usePath();
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { user, isLoading } = useAuth0();
  const { onboardingIsCompleted } = useAuth();
  const [isPending, startTransition] = useTransition();
  const { checkUserIsJustAContact } = useUserData();
  const screens = useBreakpoint();
  const { collapsed } = useMenu();

  const dashboardRef = useRef(null);
  const logoRef = useRef(null);
  const materialsLibraryRef = useRef(null);
  const myEventsRef = useRef(null);
  const myProgramsRef = useRef(null);
  const myReportingRef = useRef(null);
  const myProfileRef = useRef(null);
  const publicEventsRef = useRef(null);
  const openTutorialIconRef = useRef(null);

  const [sidebarTutorialSlug, setSidebarTutorialSlug] = useState<SidebarTutorialSlugs>();
  const [steps, setSteps] = useState<TourStepProps[]>([]);
  const [slugIsLoading, setSlugIsLoading] = useState(true);
  const [stepsAreLoading, setStepsAreLoading] = useState(true);
  const [onFinish, setOnFinish] = useState(() => () => {});
  const [shouldResetSidebarTourSlug, setShouldResetSidebarTourSlug] = useState(false);
  const [tourWasShown, setTourWasShown] = useLocalStorage(LocalStorageKeys.tourWasShown, {
    main: false,
    eventDetailsPage: false,
    openTutorialIcon: false,
  });

  const shouldShowTour = !cookiesModalIsOpen && !!user && onboardingIsCompleted && !!screens.xl && collapsed === false;

  const sidebarTourIsOpen =
    shouldShowTour &&
    !stepsAreLoading &&
    (!tourWasShown.main || sidebarTutorialSlug === SidebarTutorialSlugs.openTutorialIcon) &&
    !!steps.length;
  const pageTourIsOpen =
    shouldShowTour &&
    (pathname === dashboardUrl() || bannersAreLoaded) &&
    !stepsAreLoading &&
    !tourWasShown.main &&
    !steps.length;
  const eventDetailsPageTourIsOpen =
    shouldShowTour &&
    bannersAreLoaded &&
    !stepsAreLoading &&
    !tourWasShown.eventDetailsPage &&
    !checkUserIsJustAContact(user) &&
    !steps.length;

  const getStepByStepSlug = (slug: SidebarTutorialSlugs): TourStepProps | undefined => {
    if (!user) return;

    switch (slug) {
      case SidebarTutorialSlugs.dashboard:
        return {
          title: t("common.tour.sidebar.steps.dashboard.title"),
          description: t("common.tour.sidebar.steps.dashboard.description"),
          // @ts-ignore
          target: () => dashboardRef.current?.closest(".ant-menu-submenu-title"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.logo:
        return {
          title: t("common.tour.sidebar.steps.logo.title"),
          description: t("common.tour.sidebar.steps.logo.description"),
          target: () => logoRef.current,
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.myEvents:
        return {
          title: t("common.tour.sidebar.steps.myEvents.title"),
          description: t("common.tour.sidebar.steps.myEvents.description"),
          // @ts-ignore
          target: () => myEventsRef.current?.closest(".ant-menu-item"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.myPrograms:
        if (checkUserIsJustAContact(user)) {
          return {
            title: t("common.tour.sidebar.steps.myFavorites.title"),
            description: t("common.tour.sidebar.steps.myFavorites.description"),
            // @ts-ignore
            target: () => myProgramsRef.current?.closest(".ant-menu-item"),
            nextButtonProps: {
              children: <span>{t("common.tour.buttons.next")}</span>,
            },
          };
        } else {
          return {
            title: t("common.tour.sidebar.steps.myPrograms.title"),
            description: t("common.tour.sidebar.steps.myPrograms.description"),
            // @ts-ignore
            target: () => myProgramsRef.current?.closest(".ant-menu-item"),
            nextButtonProps: {
              children: <span>{t("common.tour.buttons.next")}</span>,
            },
          };
        }
      case SidebarTutorialSlugs.myReporting:
        return {
          title: t("common.tour.sidebar.steps.myReporting.title"),
          description: t("common.tour.sidebar.steps.myReporting.description"),
          // @ts-ignore
          target: () => myReportingRef.current?.closest(".ant-menu-item"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.myProfile:
        return {
          title: t("common.tour.sidebar.steps.myProfile.title"),
          description: t("common.tour.sidebar.steps.myProfile.description"),
          // @ts-ignore
          target: () => myProfileRef.current?.closest(".ant-menu-item"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.materialsLibrary:
        return {
          title: t("common.tour.sidebar.steps.materialsLibrary.title"),
          description: t("common.tour.sidebar.steps.materialsLibrary.description"),
          // @ts-ignore
          target: () => materialsLibraryRef.current?.closest(".ant-menu-item"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.publicEvents:
        return {
          title: t("common.tour.sidebar.steps.publicEvents.title"),
          description: t("common.tour.sidebar.steps.publicEvents.description"),
          // @ts-ignore
          target: () => publicEventsRef.current?.closest(".ant-menu-item"),
          nextButtonProps: {
            children: <span>{t("common.tour.buttons.next")}</span>,
          },
        };
      case SidebarTutorialSlugs.openTutorialIcon:
        return {
          title: t("common.tour.sidebar.steps.openTutorialIcon.title"),
          description: t("common.tour.sidebar.steps.openTutorialIcon.description"),
          target: () => openTutorialIconRef.current,
        };
    }
  };

  const getTutorialSettingsBySlug = (
    slug: SidebarTutorialSlugs,
  ): { steps: TourStepProps[]; finishHandler: () => void } => {
    if (!user)
      return {
        steps: [],
        finishHandler: () => () => {},
      };

    const steps: TourStepProps[] = [];
    let finishHandler = () => () => {};

    switch (slug) {
      case SidebarTutorialSlugs.dashboard: {
        const step = getStepByStepSlug(SidebarTutorialSlugs.dashboard);
        if (step) steps.push(step);

        finishHandler = () => () => {
          if (pathname !== dashboardUrl()) {
            startTransition(() => router.push(dashboardUrl()));
            setShouldResetSidebarTourSlug(true);
          } else {
            setSidebarTutorialSlug(undefined);
          }
        };

        break;
      }
      case SidebarTutorialSlugs.logo: {
        if (checkUserIsJustAContact(user)) {
          const logoStep = getStepByStepSlug(SidebarTutorialSlugs.logo);
          if (logoStep) steps.push(logoStep);

          const myFavoritesStep = getStepByStepSlug(SidebarTutorialSlugs.myPrograms);
          if (myFavoritesStep) steps.push(myFavoritesStep);

          const myProfileStep = getStepByStepSlug(SidebarTutorialSlugs.myProfile);
          if (myProfileStep) steps.push(myProfileStep);

          const materialsLibraryStep = getStepByStepSlug(SidebarTutorialSlugs.materialsLibrary);
          if (materialsLibraryStep) steps.push(materialsLibraryStep);

          finishHandler = () => () => {
            startTransition(() => router.push(materialsLibraryUrl()));
            setShouldResetSidebarTourSlug(true);
          };
        } else {
          const logoStep = getStepByStepSlug(SidebarTutorialSlugs.logo);
          if (logoStep) steps.push(logoStep);

          const myEventsStep = getStepByStepSlug(SidebarTutorialSlugs.myEvents);
          if (myEventsStep) steps.push(myEventsStep);

          finishHandler = () => () => {
            startTransition(() => router.push(ongoingMyEventsUrl()));
            setShouldResetSidebarTourSlug(true);
          };
        }

        break;
      }
      case SidebarTutorialSlugs.myEvents: {
        if (checkUserIsJustAContact(user)) break;

        const step = getStepByStepSlug(SidebarTutorialSlugs.myEvents);
        if (step) steps.push(step);

        finishHandler = () => () => {
          if (pathname !== ongoingMyEventsUrl() && pathname !== futureMyEventsUrl() && pathname !== pastMyEventsUrl()) {
            startTransition(() => router.push(ongoingMyEventsUrl()));
            setShouldResetSidebarTourSlug(true);
          } else {
            setSidebarTutorialSlug(undefined);
          }
        };

        break;
      }
      case SidebarTutorialSlugs.myPrograms: {
        if (checkUserIsJustAContact(user)) {
          const myFavoritesStep = getStepByStepSlug(SidebarTutorialSlugs.myPrograms);
          if (myFavoritesStep) steps.push(myFavoritesStep);

          const myProfileStep = getStepByStepSlug(SidebarTutorialSlugs.myProfile);
          if (myProfileStep) steps.push(myProfileStep);

          const materialsLibraryStep = getStepByStepSlug(SidebarTutorialSlugs.materialsLibrary);
          if (materialsLibraryStep) steps.push(materialsLibraryStep);

          finishHandler = () => () => {
            startTransition(() => router.push(materialsLibraryUrl()));
            setShouldResetSidebarTourSlug(true);
          };
        } else {
          const step = getStepByStepSlug(SidebarTutorialSlugs.myPrograms);
          if (step) steps.push(step);

          finishHandler = () => () => {
            if (!pathname.startsWith(MY_PROGRAMS_BASE_PATH)) {
              startTransition(() => router.push(favoritesProgramsUrl()));
              setShouldResetSidebarTourSlug(true);
            } else {
              setSidebarTutorialSlug(undefined);
            }
          };
        }

        break;
      }
      case SidebarTutorialSlugs.myReporting: {
        if (checkUserIsJustAContact(user)) break;

        const step = getStepByStepSlug(SidebarTutorialSlugs.myReporting);
        if (step) steps.push(step);

        finishHandler = () => () => {
          if (!pathname.startsWith(MY_REPORTING_BASE_PATH)) {
            startTransition(() => router.push(myHealingReportingUrl(0)));
            setShouldResetSidebarTourSlug(true);
          } else {
            setSidebarTutorialSlug(undefined);
          }
        };

        break;
      }
      case SidebarTutorialSlugs.myProfile: {
        if (checkUserIsJustAContact(user)) {
          const myProfileStep = getStepByStepSlug(SidebarTutorialSlugs.myProfile);
          if (myProfileStep) steps.push(myProfileStep);

          const materialsLibraryStep = getStepByStepSlug(SidebarTutorialSlugs.materialsLibrary);
          if (materialsLibraryStep) steps.push(materialsLibraryStep);

          finishHandler = () => () => {
            startTransition(() => router.push(materialsLibraryUrl()));
            setShouldResetSidebarTourSlug(true);
          };
        } else {
          const step = getStepByStepSlug(SidebarTutorialSlugs.myProfile);
          if (step) steps.push(step);

          finishHandler = () => () => {
            if (!pathname.startsWith(MY_PROFILE_BASE_PATH)) {
              startTransition(() => router.push(personalInfoUrl()));
              setShouldResetSidebarTourSlug(true);
            } else {
              setSidebarTutorialSlug(undefined);
            }
          };
        }

        break;
      }
      case SidebarTutorialSlugs.materialsLibrary: {
        const step = getStepByStepSlug(SidebarTutorialSlugs.materialsLibrary);
        if (step) steps.push(step);

        finishHandler = () => () => {
          if (pathname !== materialsLibraryUrl()) {
            startTransition(() => router.push(materialsLibraryUrl()));
            setShouldResetSidebarTourSlug(true);
          } else {
            setSidebarTutorialSlug(undefined);
          }
        };

        break;
      }
      case SidebarTutorialSlugs.publicEvents: {
        const step = getStepByStepSlug(SidebarTutorialSlugs.publicEvents);
        if (step) steps.push(step);

        finishHandler = () => () => {
          if (pathname !== publicEventsUrl() || searchParams.size) {
            startTransition(() => router.push(publicEventsUrl()));
            setShouldResetSidebarTourSlug(true);
          } else {
            setSidebarTutorialSlug(undefined);
          }
        };

        break;
      }
      case SidebarTutorialSlugs.openTutorialIcon: {
        const step = getStepByStepSlug(SidebarTutorialSlugs.openTutorialIcon);
        if (step) steps.push(step);

        finishHandler = () => () => {
          setTourWasShown({ ...tourWasShown, openTutorialIcon: true });
          setSidebarTutorialSlug(undefined);
        };
      }
    }

    return { steps, finishHandler };
  };

  useEffect(() => {
    if (isLoading || !user || !onboardingIsCompleted || !!steps.length || tourWasShown.main) return;

    setSlugIsLoading(true);
    if (!stepsAreLoading) setStepsAreLoading(true);

    let slug: SidebarTutorialSlugs | undefined;

    if (pathname === dashboardUrl()) {
      slug = SidebarTutorialSlugs.dashboard;
    } else if (pathname.startsWith(MY_EVENTS_BASE_PATH)) {
      slug = SidebarTutorialSlugs.myEvents;
    } else if (pathname.startsWith(MY_PROGRAMS_BASE_PATH)) {
      slug = SidebarTutorialSlugs.myPrograms;
    } else if (pathname.startsWith(MY_REPORTING_BASE_PATH)) {
      slug = SidebarTutorialSlugs.myReporting;
    } else if (pathname.startsWith(MY_PROFILE_BASE_PATH)) {
      slug = SidebarTutorialSlugs.myProfile;
    } else if (pathname === materialsLibraryUrl()) {
      slug = SidebarTutorialSlugs.materialsLibrary;
    } else if (pathname.startsWith(PUBLIC_EVENTS_BASE_PATH)) {
      slug = SidebarTutorialSlugs.publicEvents;
    }

    setSidebarTutorialSlug(slug);
    setSlugIsLoading(false);
  }, [isLoading, tourWasShown.main, onboardingIsCompleted]);

  useEffect(() => {
    if (slugIsLoading) return;

    if (sidebarTutorialSlug) {
      const { steps: stepsToSet, finishHandler } = sidebarTutorialSlug
        ? getTutorialSettingsBySlug(sidebarTutorialSlug)
        : { steps: [], finishHandler: () => () => {} };

      setSteps(stepsToSet);
      setOnFinish(finishHandler);
    } else {
      if (steps.length) setSteps([]);
      setOnFinish(() => () => {});
    }

    setStepsAreLoading(false);
  }, [sidebarTutorialSlug]);

  useEffect(() => {
    if (isPending || !shouldResetSidebarTourSlug) return;

    setShouldResetSidebarTourSlug(false);
    setSidebarTutorialSlug(undefined);
  }, [isPending]);

  return (
    <SidebarTutorialContext.Provider
      value={{
        sidebarTutorialSlug,
        tourWasShown,
        sidebarTourIsOpen,
        pageTourIsOpen,
        eventDetailsPageTourIsOpen,
        steps,
        dashboardRef,
        logoRef,
        materialsLibraryRef,
        myEventsRef,
        myProgramsRef,
        myReportingRef,
        myProfileRef,
        publicEventsRef,
        openTutorialIconRef,
        setSidebarTutorialSlug,
        setTourWasShown,
        onFinish,
      }}
    >
      {children}
    </SidebarTutorialContext.Provider>
  );
};

export const useSidebarTutorial = () => useContext(SidebarTutorialContext);
