"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { Grid } from "antd/lib";

type MenuContextType = {
  collapsed: boolean | undefined;
  setCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
};

const MenuContext = createContext<MenuContextType>({
  collapsed: undefined,
  setCollapsed: () => undefined,
});

const { useBreakpoint } = Grid;

interface MenuProviderProps {
  children: ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState<boolean>();

  useEffect(() => {
    if (screens.lg === undefined) return;

    setCollapsed(!screens.lg);
  }, [screens.lg]);

  return <MenuContext.Provider value={{ collapsed, setCollapsed }}>{children}</MenuContext.Provider>;
};

export const useMenu = () => useContext(MenuContext);
